<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button :title="$t('sidebar.email_templates')"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <email-template-datatable/>
      </div>
    </div>
  </div>
</template>

<script>

import EmailTemplateDatatable from "@/pages/Settings/datatables/EmailTemplateDatatable";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";

export default {
  name: "EmailTemplatePage",
  components: {OctoHeaderButton, EmailTemplateDatatable}
}
</script>

<style scoped>

</style>
